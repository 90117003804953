import React from "react";

interface InputFieldProps {
  id: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  disabled?: boolean;
  errorMessage?: string;
  required?: boolean; // Add `required` prop as optional
}

const InputField: React.FC<InputFieldProps> = ({
  id,
  type,
  value,
  onChange,
  placeholder,
  disabled,
  errorMessage,
  required = false, // Default is false if not provided
}) => {
  return (
    <div className="relative mb-4">
      <input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={`w-full p-3 border rounded ${
          errorMessage ? "border-red-500" : "border-gray-300"
        } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150`}
        placeholder={placeholder}
        required={required} // Use required prop here
      />
      {errorMessage && (
        <p className="text-red-600 text-sm mt-1">{errorMessage}</p>
      )}
    </div>
  );
};

export default InputField;
