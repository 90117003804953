import axios from 'axios';

// Define the Question interface
export interface Question {
  _id: string;  // MongoDB ObjectId as a string
  question_text: string;
  question_image_url?: string;
  options: string[];
  correct_answers: string[];
  subject_id: string;  // MongoDB ObjectId as a string
  level_id: string;  // MongoDB ObjectId as a string
  explanation_text?: string;
  explanation_image_url?: string;
  topic: string[];  // Array of MongoDB ObjectId as strings
}

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const fetchAllQuestions = async (): Promise<Question[]> => {
  try {
    const response = await axios.get<Question[]>(`${SERVER_URL}/questions`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all questions:', error);
    throw error;
  }
};

export const fetchQuestionsByTopic = async (topicId: string): Promise<Question[]> => {
  try {
    const response = await axios.get<Question[]>(`${SERVER_URL}/questions/by-topic?topic_id=${topicId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching questions by topic:`, error);
    throw error;
  }
};

export const fetchAndPreloadQuestions = async (selectedTopic?: string | null, retryCount: number = 3): Promise<Question[]> => {
  let attempts = 0;
  let questionsData: Question[] = [];

  while (attempts < retryCount) {
    try {
      if (selectedTopic && selectedTopic !== "all") {
        questionsData = await fetchQuestionsByTopic(selectedTopic);
      } else {
        questionsData = await fetchAllQuestions();
      }

      if (!questionsData.length) {
        throw new Error("No questions available.");
      }

      return questionsData;
    } catch (error) {
      attempts++;
      if (attempts >= retryCount) {
        console.error("Failed to load questions after multiple attempts:", error);
        throw error;
      }
    }
  }

  return questionsData;
};
