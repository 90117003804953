import React, { useState } from "react";
import { useAppDispatch } from "../../../stores/store";
import { useSelector } from "react-redux";
import { login, googleLogin } from "../services/authActions";
import { RootState } from "../../../stores/store";
import { useNavigate, Link } from "react-router-dom";
import InputField from "../components/InputField";
import Button from "../components/Button";
import ErrorMessage from "../components/ErrorMessage";
import Divider from "../components/Divider";
import FullScreenLoader from "../../../components/FullScreenLoader";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state: RootState) => state.auth.loading);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // Reset error before submission

    if (!email || !password) {
      setError("Please fill in both email and password.");
      return;
    }

    try {
      // Dispatch the login action
      await dispatch(login(email, password));
      navigate("/quiz"); // Redirect on success
    } catch (error: any) {
      setError(error.message || "Login failed. Please try again.");
    }
  };

  const handleGoogleLogin = () => {
    dispatch(googleLogin()); // Trigger Google login
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {loading && (
        <FullScreenLoader loadingTexts={["Logging in...", "Please wait..."]} />
      )}

      <div
        className={`w-full max-w-2xl bg-white shadow-lg rounded-lg p-10 md:p-12 space-y-6 transform transition-all duration-500 ${
          loading ? "pointer-events-none opacity-50" : ""
        }`}
        style={{ minWidth: "400px", maxWidth: "750px" }} // Slightly larger card on larger screens
      >
        <h2 className="text-4xl font-bold text-gray-900 text-center">Login</h2>

        {/* Display Error Message if it exists */}
        {error && <ErrorMessage message={error} />}

        <form onSubmit={handleLogin}>
          <InputField
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            errorMessage={error && !email ? "Email is required" : undefined}
            disabled={loading} // Disable input during loading
          />
          <InputField
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required
            errorMessage={
              error && !password ? "Password is required" : undefined
            }
            disabled={loading} // Disable input during loading
          />
          <Button text="Sign In" loading={loading} disabled={loading} />
        </form>

        <Divider />
        <div className="grid grid-cols-2 gap-4">
          {/* Google Login Button */}
          <button
            className={`col-span-1 p-3 border border-neutral-300 rounded-lg flex items-center justify-center shadow-sm ${
              loading
                ? "pointer-events-none"
                : "cursor-pointer hover:bg-neutral-100"
            }`}
            onClick={handleGoogleLogin}
            disabled={loading}
          >
            <img
              className="w-4 h-auto flex-shrink-0 ml-1.5 mr-1"
              src="/assets/google-DJOPPODp.png"
              alt="Google logo"
            />
            <div>Google</div>
          </button>

          {/* Facebook Login Button (optional, currently disabled) */}
          <button
            className="col-span-1 p-3 border border-neutral-300 rounded-lg flex items-center justify-center shadow cursor-not-allowed bg-neutral-200 text-neutral-400"
            disabled
          >
            <img
              className="w-4 h-auto flex-shrink-0 ml-1.5 mr-1"
              src="/assets/facebook-BZE0LojR.png"
              alt="Facebook logo"
            />
            <div>Facebook</div>
          </button>
        </div>
        <p className="text-center text-gray-600 text-sm">
          Don't have an account?{" "}
          <Link
            to="/register"
            className={`text-blue-500 hover:text-blue-800 font-bold ${
              loading ? "pointer-events-none" : ""
            }`}
          >
            Register here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
