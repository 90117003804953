import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchLevels, fetchSubjects, Level, Subject } from '../features/upload/services/uploadService';
import { Topic, fetchTopics} from '../services/topicService';

interface PreloadState {
  topics: Topic[];
  levels: Level[];
  subjects: Subject[];
  loading: boolean;
}

const initialState: PreloadState = {
  topics: [],
  levels: [],
  subjects: [],
  loading: false,
};

export const preloadData = createAsyncThunk(
  'preload/fetchData',
  async () => {
    const topics = await fetchTopics();
    const levels = await fetchLevels();
    const subjects = await fetchSubjects();
    return { topics, levels, subjects };
  }
);

const preloadSlice = createSlice({
  name: 'preload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(preloadData.pending, (state) => {
        state.loading = true;
      })
      .addCase(preloadData.fulfilled, (state, action) => {
        state.topics = action.payload.topics;
        state.levels = action.payload.levels;
        state.subjects = action.payload.subjects;
        state.loading = false;
      })
      .addCase(preloadData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default preloadSlice.reducer;
