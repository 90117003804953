import React, { useState, useEffect } from "react";
import { BiExpand } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";

interface ImageWithExpandProps {
  src: string;
  alt: string;
}

const ImageWithExpand: React.FC<ImageWithExpandProps> = ({ src, alt }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [naturalWidth, setNaturalWidth] = useState<number | null>(null);
  const [naturalHeight, setNaturalHeight] = useState<number | null>(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    setNaturalWidth(event.currentTarget.naturalWidth);
    setNaturalHeight(event.currentTarget.naturalHeight);
  };

  return (
    <div className="relative w-full flex flex-col items-center bg-white mt-4">
      <div className="relative w-full flex justify-end mb-2">
        <button
          onClick={openModal}
          className="p-2 rounded-full hover:bg-gray-200"
          style={{
            backgroundColor: "transparent",
            color: "#6B7280",
            zIndex: 1,
            position: "relative",
          }}
        >
          <BiExpand size={20} />
        </button>
      </div>
      <div className="w-full flex justify-center items-center">
        <img
          src={src}
          alt={alt}
          className="object-contain w-full h-auto"
          style={{
            maxWidth: naturalWidth ? `${naturalWidth}px` : "100%",
            maxHeight: naturalHeight ? `${naturalHeight}px` : "calc(80vh - 20px)",
          }}
          onClick={openModal}
          onLoad={handleImageLoad}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex justify-center items-center">
          <div className="relative p-4 w-full max-w-full flex flex-col items-center">
            <button
              onClick={closeModal}
              className="fixed top-4 right-4 p-2 rounded-full hover:bg-gray-200"
              style={{
                backgroundColor: "transparent",
                color: "#B5B7BB",
                zIndex: 10,
              }}
            >
              <FaTimes size={20} />
            </button>
            <div
              className="overflow-auto w-full flex justify-center items-start"
              style={{
                maxHeight: "100vh",
                paddingTop: "2rem",
                paddingBottom: "4rem",
              }}
            >
              <img
                src={src}
                alt={alt}
                className="object-contain"
                style={{
                  maxWidth: "100%", // Ensures image does not exceed the viewport width
                  maxHeight: "100vh", // Prevents image from exceeding viewport height
                  width: naturalWidth ? `${naturalWidth}px` : "auto",
                  height: naturalHeight ? `${naturalHeight}px` : "auto",
                }}
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageWithExpand;
