import React from "react";

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <div
      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
      role="alert"
      style={{ border: '2px solid red' }} // Add styling to test visibility
    >
      <span className="block sm:inline">{message}</span>
    </div>
  );
};

export default ErrorMessage;
