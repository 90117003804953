import React, { useEffect, useState } from "react";

interface LoaderProps {
  loadingTexts: string[]; // Accept an array of texts
  interval?: number; // Optional interval for text change, default to 3 seconds
}

const Loader: React.FC<LoaderProps> = ({ loadingTexts, interval = 2500 }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const textChangeInterval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
    }, interval);

    return () => clearInterval(textChangeInterval);
  }, [loadingTexts.length, interval]);

  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <div className="w-16 h-16 border-4 border-dashed rounded-full animate-spin border-blue-500 mb-4"></div>
      <p className="text-gray-700 text-lg font-medium">
        {loadingTexts[currentTextIndex]}
      </p>
    </div>
  );
};

export default Loader;
