import axios from 'axios';


export interface Topic {
  _id: string;
  name: string;
}

// Access the environment variables
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// Function to fetch all topics
export const fetchTopics = async (): Promise<Topic[]> => {
  try {
    const response = await axios.get<Topic[]>(`${SERVER_URL}/topics`);
    return response.data;
  } catch (error) {
    console.error('Error fetching topics:', error);
    throw error;
  }
};

