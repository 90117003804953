import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../stores/store";
import { logoutUser } from "../features/auth/services/authActions";
import { useNavigate } from "react-router-dom";
import FullScreenLoader from "./FullScreenLoader"; // Assuming FullScreenLoader is the loader component

const NavBar: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false); // State for logout loading
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLogout = async () => {
    setIsLoggingOut(true); // Show loader
    await dispatch(logoutUser()); // Perform logout
    navigate("/login");
    setIsLoggingOut(false); // Hide loader once logout is complete
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  // Close the dropdown if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  if (isLoggingOut) {
    // Show loader during logout
    return (
      <FullScreenLoader loadingTexts={["Logging out...", "Please wait..."]} />
    );
  }

  return (
    <nav className="sticky top-0 z-50 w-full bg-blue-600 text-white p-4 flex justify-between items-center shadow-md">
      <h1
        className="text-xl font-bold cursor-pointer"
        onClick={() => navigate("/quiz")}
      >
        Smarted
      </h1>
      <div className="relative">
        {user && (
          <div className="flex items-center space-x-2 sm:space-x-4">
            <button
              onClick={toggleDropdown}
              className="relative z-10 focus:outline-none flex items-center space-x-1 sm:space-x-2"
            >
              <span className="font-medium text-sm sm:text-base">
                {user.fullName}
              </span>
              <svg
                className={`w-4 h-4 sm:w-5 sm:h-5 transform transition-transform duration-200 ${
                  isDropdownOpen ? "rotate-180" : ""
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className="absolute right-0 mt-2 w-40 sm:w-48 bg-white text-gray-800 rounded-lg shadow-lg py-2 z-50"
                style={{ top: "100%" }} // Ensure dropdown is positioned below the button
              >
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                >
                  Logout
                </button>
                {/* <button
                  onClick={() => navigate("/profile")}
                  className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                >
                  Profile
                </button>
                <button
                  onClick={() => navigate("/dashboard")}
                  className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                >
                  Dashboard
                </button> */}
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
