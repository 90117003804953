import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOptions } from "../../../stores/quizSlice";
import { RootState } from "../../../stores/store";
import ImageWithExpand from "./ImageWithExpand"; // Import the new component

interface QuestionProps {
  questionIndex: number;
  selectedOptions: string[];
  onSelectOption: (option: string) => void;
  onSubmit: () => void;
  showFeedback: boolean;
}

const Question: React.FC<QuestionProps> = ({
  questionIndex,
  selectedOptions,
  onSelectOption,
  onSubmit,
  showFeedback,
}) => {
  const question = useSelector(
    (state: RootState) => state.quiz.questions[questionIndex]
  );

  const dispatch = useDispatch();

  const handleSelectOption = (option: string) => {
    if (question.correct_answers.length === 1) {
      dispatch(setSelectedOptions([option]));
    } else {
      if (selectedOptions.includes(option)) {
        dispatch(
          setSelectedOptions(selectedOptions.filter((o) => o !== option))
        );
      } else {
        dispatch(setSelectedOptions([...selectedOptions, option]));
      }
    }
  };

  if (!question) {
    return <div>Error: Question not found.</div>;
  }

  return (
    <div
      className="relative p-6 bg-white rounded-xl shadow-lg mx-auto"
      style={{ minWidth: "50%" }}
    >
      {question.question_text && (
        <h2 className="text-xl md:text-2xl font-bold mb-2 text-gray-800 text-center">
          {question.question_text}
        </h2>
      )}
      {question.question_image_url && (
        <ImageWithExpand
          src={question.question_image_url}
          alt="Question Image"
        />
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
        {question.options.map((option) => {
          const isSelected = selectedOptions.includes(option);
          const isCorrectAnswer = question.correct_answers.includes(option);

          let buttonClasses =
            "p-3 rounded-lg text-base font-semibold transition-colors ";

          if (isSelected) {
            if (showFeedback) {
              buttonClasses += isCorrectAnswer
                ? "bg-green-500 text-white"
                : "bg-red-500 text-white";
            } else {
              buttonClasses += "bg-blue-500 text-white";
            }
          } else {
            buttonClasses += "bg-gray-200 text-gray-800 hover:bg-blue-100";
          }

          return (
            <button
              key={option}
              className={buttonClasses}
              onClick={() => handleSelectOption(option)}
              disabled={showFeedback}
            >
              {option}
            </button>
          );
        })}
      </div>
      {!showFeedback && (
        <div className="mt-6 text-center">
          <button
            onClick={onSubmit}
            className="p-3 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition-colors"
          >
            Submit Answer
          </button>
        </div>
      )}

      {showFeedback && question.explanation_image_url && (
        <div className="mt-6 text-center">
          {question.explanation_text && (
            <p className="text-gray-700 text-base md:text-lg">
              {question.explanation_text}
            </p>
          )}
          <ImageWithExpand
            src={question.explanation_image_url}
            alt="Explanation Image"
          />
        </div>
      )}
    </div>
  );
};

export default Question;
