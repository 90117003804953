import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface User {
  id: string;
  email: string;
  fullName: string;
  role: string;
}

interface AuthState {
  user: User | null;
  loading: boolean;
  error: string | null;
  isAuthenticated: boolean;  // This flag will track the authentication state
}

// Initial state no longer stores tokens, just user info
const initialState: AuthState = {
  user: null, // Only store the user info
  loading: false,
  error: null,
  isAuthenticated: false,  // Initially, the user is not authenticated
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // When login starts, set loading state
    loginStart(state) {
      state.loading = true;
      state.error = null;
    },

    // On login success, store user info and set isAuthenticated to true
    loginSuccess(state, action: PayloadAction<{ user: User }>) {
      state.loading = false;
      state.user = action.payload.user;
      state.isAuthenticated = true;  // Mark the user as authenticated
    },

    // On login failure, stop loading and store error
    loginFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;  // Ensure user is marked as not authenticated
    },

    // On logout, clear user info and mark as unauthenticated
    logout(state) {
      state.user = null;
      state.isAuthenticated = false;  // Mark the user as logged out
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logout } = authSlice.actions;
export default authSlice.reducer;
