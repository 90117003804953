import React from "react";

interface ButtonProps {
  text: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ text, onClick, loading, disabled }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition-transform transform hover:scale-105 focus:scale-100 ${
        loading || disabled ? "cursor-not-allowed opacity-50" : ""
      }`}
    >
      {loading ? "Loading..." : text}
    </button>
  );
};

export default Button;
