import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../stores/store";
import Loader from "../../../components/Loader";
import { preloadData } from "../../../stores/preloadSlice";

interface QuizSelectorProps {
  onStartQuiz: (quizName: string) => void;
  onBackToMenu: () => void; // Add prop for navigating back to the main menu
}

const QuizSelector: React.FC<QuizSelectorProps> = ({
  onStartQuiz,
  onBackToMenu,
}) => {
  const dispatch = useAppDispatch();
  const topics = useSelector((state: RootState) => state.preload.topics);
  const loading = useSelector((state: RootState) => state.preload.loading);

  const [selectedTopic, setSelectedTopic] = useState<string | null>(null);

  // Only dispatch preloadData if topics have not been loaded yet
  useEffect(() => {
    if (topics.length === 0) {
      dispatch(preloadData());
    }
  }, [dispatch, topics.length]);

  if (loading && topics.length === 0) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader
          loadingTexts={[
            "Loading topics...",
            "Just a moment...",
            "Almost ready...",
          ]}
          interval={3000}
        />
      </div>
    );
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTopic(e.target.value);
  };

  const handleStartQuiz = () => {
    if (selectedTopic) {
      onStartQuiz(selectedTopic);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-8 bg-gray-100">
      <h2 className="text-3xl font-bold text-gray-800 mb-8">Select a Quiz</h2>
      <div className="flex flex-col items-center justify-center space-y-4">
        <div className="w-full max-w-xs">
          <select
            className="w-full p-3 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={handleSelectChange}
            value={selectedTopic || ""}
          >
            <option value="" disabled>
              Select a quiz topic
            </option>
            <option value="all">All Questions</option>
            {topics.map((topic) => (
              <option key={topic._id} value={topic._id}>
                {topic.name}
              </option>
            ))}
          </select>
        </div>
        <button
          className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow-lg hover:bg-blue-700 transition-colors"
          onClick={handleStartQuiz}
          disabled={!selectedTopic}
        >
          Start Quiz
        </button>
        {/* Standardized Back to Menu Button */}
        {/* <button
          className="px-6 py-3 bg-gray-600 text-white rounded-lg shadow-lg hover:bg-gray-700 transition-colors mt-4"
          onClick={onBackToMenu}
        >
          Back to Menu
        </button> */}
      </div>
    </div>
  );
};

export default QuizSelector;
