import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Question from "./Question";
import Feedback from "./Feedback";
import Confetti from "./Confetti";
import Loader from "../../../components/Loader";
import QuizCompleted from "./QuizCompleted";
import QuizHeader from "./QuizHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  setQuestions,
  setCurrentQuestionIndex,
  setSelectedOptions,
  setScore,
  setStreak,
  setLongestStreak,
  setShowFeedback,
  setIsFeedbackCorrect,
  setFeedbackMessage,
  setShowResult,
  setShowConfetti,
  resetQuiz,
} from "../../../stores/quizSlice";
import { RootState } from "../../../stores/store";
import { fetchAndPreloadQuestions } from "../services/quizService";

interface QuizProps {
  onBackToMenu: () => void;
  selectedTopic?: string | null;
}

const loadImage = (src?: string): Promise<void> => {
  if (!src) {
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve();
    img.onerror = (error) => {
      console.error("Failed to load image:", src, error);
      resolve();
    };
  });
};

const preloadImages = async (questions: any[]): Promise<void[]> => {
  const imagePromises = questions.flatMap((question) => [
    loadImage(question.question_image_url),
    loadImage(question.explanation_image_url),
  ]);
  return Promise.all(imagePromises);
};

const Quiz: React.FC<QuizProps> = ({ onBackToMenu, selectedTopic }) => {
  const dispatch = useDispatch();

  const {
    questions,
    currentQuestionIndex,
    selectedOptions,
    score,
    streak,
    longestStreak,
    showFeedback,
    isFeedbackCorrect,
    feedbackMessage,
    showResult,
    showConfetti,
  } = useSelector((state: RootState) => state.quiz);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [contentLoading, setContentLoading] = useState(false);

  useEffect(() => {
    const loadQuestions = async () => {
      try {
        const questionsData = await fetchAndPreloadQuestions(selectedTopic);
        dispatch(setQuestions(questionsData));
        await preloadImages(questionsData);
        setLoading(false);
      } catch (error) {
        setError("Failed to load questions. Please try again later.");
        setLoading(false);
      }
    };

    loadQuestions();
  }, [dispatch, selectedTopic]);

  const nextQuestion = useMemo(() => {
    if (currentQuestionIndex < questions.length - 1) {
      return questions[currentQuestionIndex + 1];
    }
    return null;
  }, [currentQuestionIndex, questions]);

  const handleSelectOption = (option: string) => {
    const currentQuestion = questions[currentQuestionIndex];
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((o) => o !== option)
      : [...selectedOptions, option];

    dispatch(
      setSelectedOptions(
        currentQuestion.correct_answers.length === 1 ? [option] : updatedOptions
      )
    );
  };

  const evaluateAnswer = () => {
    setContentLoading(true);
    const currentQuestion = questions[currentQuestionIndex];
    const isCorrect =
      selectedOptions.length === currentQuestion.correct_answers.length &&
      selectedOptions.every((answer) =>
        currentQuestion.correct_answers.includes(answer)
      );

    dispatch(setIsFeedbackCorrect(isCorrect));

    if (isCorrect) {
      dispatch(setScore(score + 10));
      dispatch(setStreak(streak + 1));
      dispatch(
        setFeedbackMessage(
          `🎉 Correct! ${
            streak > 1 ? `That's ${streak} in a row! Keep it up!` : ""
          }`
        )
      );
      dispatch(setShowConfetti(true));
      setTimeout(() => dispatch(setShowConfetti(false)), 2000);

      if (streak + 1 > longestStreak) {
        dispatch(setLongestStreak(streak + 1));
      }
    } else {
      dispatch(setStreak(0));
      dispatch(
        setFeedbackMessage("❌ Incorrect. The correct answer is shown below.")
      );
    }

    dispatch(setShowFeedback(true));
    setContentLoading(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      dispatch(setCurrentQuestionIndex(currentQuestionIndex + 1));
      dispatch(setSelectedOptions([]));
      dispatch(setShowFeedback(false));
    } else {
      dispatch(setShowResult(true));
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleFinishQuiz = () => {
    dispatch(setShowResult(true));
  };

  const handleRestartQuiz = () => {
    dispatch(resetQuiz());
  };

  const currentQuestion = questions[currentQuestionIndex];
  const questionId =
    showFeedback && currentQuestionIndex > 0
      ? questions[currentQuestionIndex - 1]._id
      : currentQuestion?._id;

  if (loading) {
    return (
      <Loader
        loadingTexts={[
          "Preparing Quiz...",
          "Just a moment...",
          "Almost ready...",
        ]}
        interval={3000}
      />
    );
  }

  if (error || !currentQuestion) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4 bg-gray-100">
        <div className="text-center text-red-600 text-lg mb-4">
          {error || "No questions available."}
        </div>
        <button
          onClick={onBackToMenu}
          className="text-black hover:text-gray-800 transition-colors"
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            border: "none",
            background: "none",
            cursor: "pointer",
          }}
          aria-label="Back to Menu"
        >
          <ArrowBackIcon style={{ fontSize: "2rem" }} />
          <span> Back to Menu</span>
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4 bg-gray-100 relative">
      {showConfetti && <Confetti />}

      <QuizHeader
        currentQuestionIndex={currentQuestionIndex}
        totalQuestions={questions.length}
        streak={streak}
        score={score}
        onBackToMenu={onBackToMenu}
        showFeedback={showFeedback}
      />

      <div className=" w-full max-w-2xl mb-6 min-h-[calc(100vh-160px)]">
        {contentLoading ? (
          <Loader loadingTexts={["Loading..."]} interval={0} />
        ) : (
          <>
            {!showResult && !contentLoading ? (
              showFeedback ? (
                <Feedback
                  isCorrect={isFeedbackCorrect}
                  feedbackMessage={feedbackMessage}
                  streak={streak}
                  onNext={handleNextQuestion}
                  onFinish={handleFinishQuiz}
                  isLastQuestion={currentQuestionIndex === questions.length - 1}
                  questionIndex={currentQuestionIndex}
                />
              ) : (
                <Question
                  questionIndex={currentQuestionIndex}
                  selectedOptions={selectedOptions}
                  onSelectOption={handleSelectOption}
                  onSubmit={evaluateAnswer}
                  showFeedback={showFeedback}
                />
              )
            ) : (
              !contentLoading && (
                <QuizCompleted
                  score={score}
                  longestStreak={longestStreak}
                  onRestart={handleRestartQuiz}
                  onBackToMenu={onBackToMenu}
                />
              )
            )}
          </>
        )}
      </div>

      <div className="absolute bottom-4 w-full max-w-2xl text-gray-500 text-xs text-center">
        Question ID: {questionId}
      </div>
    </div>
  );
};

export default Quiz;
