import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../stores/store";
import { completeProfile } from "../services/authActions"; // You will create this action
import { fetchSchools, School } from "../../../services/schoolService";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import InputField from "../components/InputField";
import ErrorMessage from "../components/ErrorMessage";

const CompleteProfile: React.FC = () => {
  const [fullName, setFullName] = useState("");
  const [school, setSchool] = useState("");
  const [schools, setSchools] = useState<School[]>([]);
  const [loading, setLoading] = useState(true);
  const [profileLoading, setProfileLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Fetch schools when the component mounts
  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("Token from localStorage:", token); // This will log the token
    const loadSchools = async () => {
      try {
        const fetchedSchools = await fetchSchools();
        setSchools(fetchedSchools);
      } catch (error) {
        console.error("Failed to fetch schools", error);
      } finally {
        setLoading(false);
      }
    };
    loadSchools();
  }, []);
  const handleCompleteProfile = async (e: React.FormEvent) => {
    e.preventDefault();

    setProfileLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = (await dispatch(
        completeProfile(fullName, school)
      )) as any;

      // Log the response and status
      console.log("Complete profile response:", response);

      if (response.status === 200) {
        setSuccess(true);
        navigate("/quiz"); // Redirect to the dashboard after completion
      } else {
        setError("Something went wrong.");
      }
    } catch (error: any) {
      console.error("Profile completion error:", error);
      console.log(error);
      setError(error.response?.data?.message || "An unknown error occurred.");
    } finally {
      setProfileLoading(false);
    }
  };
  if (loading) {
    return (
      <Loader
        loadingTexts={[
          "Fetching schools...",
          "Almost there...",
          "Loading data...",
        ]}
      />
    );
  }

  return (
    <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
      <button
        onClick={() => navigate(-1)}
        className="text-blue-600 hover:text-blue-800 mb-4"
      >
        &larr; Back
      </button>

      <h2 className="text-2xl font-bold text-center mb-6">
        Complete Your Profile
      </h2>

      {error && <ErrorMessage message={error} />}
      {success && (
        <p className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          Profile completed! Redirecting...
        </p>
      )}

      <form onSubmit={handleCompleteProfile}>
        <InputField
          id="fullName"
          type="text"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          placeholder="Full Name"
          disabled={profileLoading}
        />
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">
            Select School:
          </label>
          <select
            value={school}
            onChange={(e) => setSchool(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded"
            disabled={profileLoading}
            required
          >
            <option value="">Select a school</option>
            {schools.map((school) => (
              <option key={school._id} value={school._id}>
                {school.name}
              </option>
            ))}
          </select>
        </div>

        <button
          type="submit"
          className={`w-full p-3 text-white rounded ${
            profileLoading ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"
          }`}
          disabled={profileLoading}
        >
          {profileLoading ? "Completing..." : "Complete Profile"}
        </button>
      </form>
    </div>
  );
};

export default CompleteProfile;
