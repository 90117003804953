import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import { FaSadTear } from "react-icons/fa";
import ImageWithExpand from "./ImageWithExpand"; // Import the new component

interface FeedbackProps {
  isCorrect: boolean;
  feedbackMessage: string;
  streak: number;
  onNext: () => void;
  onFinish: () => void;
  isLastQuestion: boolean;
  questionIndex: number;
}

const Feedback: React.FC<FeedbackProps> = ({
  isCorrect,
  feedbackMessage,
  streak,
  onNext,
  onFinish,
  isLastQuestion,
  questionIndex,
}) => {
  const question = useSelector(
    (state: RootState) => state.quiz.questions[questionIndex]
  );

  const [showQuestion, setShowQuestion] = useState(false);

  const handleToggleQuestion = () => {
    setShowQuestion(!showQuestion);
  };

  if (!question) {
    return <div>Error: Question not found.</div>;
  }

  return (
    <div
      className="flex flex-col items-center justify-between p-6 bg-white rounded-lg shadow-lg mx-auto text-center space-y-6"
      style={{
        width: "100%",
        maxWidth: "90vw",
        minWidth: "50%",
        height: "auto",
      }}
    >
      {/* Feedback Message */}
      <h2
        className={`text-2xl font-bold ${
          isCorrect ? "text-green-600" : "text-red-600"
        }`}
      >
        {feedbackMessage}
      </h2>

      {/* Correct Answer Display */}
      {!isCorrect && (
        <div className="flex items-center justify-center text-gray-800">
          <FaSadTear className="text-3xl text-blue-500 mr-2" />
          <p className="text-lg">
            The correct answer
            {question.correct_answers.length > 1 ? "s were" : " was"}:{" "}
            <strong>{question.correct_answers.join(", ")}</strong>
          </p>
        </div>
      )}

      {/* View Question Button */}
      <button
        className="p-4 bg-gray-200 text-gray-800 rounded-full shadow-lg hover:bg-gray-300 transition-colors w-3/4"
        onClick={handleToggleQuestion}
      >
        {showQuestion ? "Hide Question" : "View Question"}
      </button>

      {/* Question Display */}
      {showQuestion && (
        <div className="mt-6 w-full text-left">
          <h3 className="text-xl font-bold text-gray-800 mb-4 text-center">
            Question
          </h3>
          <div className="mb-6">
            {question.question_text && (
              <h4 className="text-lg md:text-xl font-bold mb-2 text-gray-700 text-center">
                {question.question_text}
              </h4>
            )}
            {question.question_image_url && (
              <ImageWithExpand
                src={question.question_image_url}
                alt="Question Image"
              />
            )}
          </div>
          {/* Minimalistic Horizontal Line */}
          <hr className="my-6 border-gray-300 w-2/3 mx-auto" />
        </div>
      )}

      {/* Explanation Section */}
      {(question.explanation_text || question.explanation_image_url) && (
        <div className="mt-6 w-full text-left">
          <h3 className="text-xl font-bold text-gray-800 mb-4 text-center">
            Explanation
          </h3>
          <div>
            {question.explanation_text && (
              <p className="text-gray-700 text-base md:text-lg mb-4 text-center">
                {question.explanation_text}
              </p>
            )}
            {question.explanation_image_url && (
              <ImageWithExpand
                src={question.explanation_image_url}
                alt="Explanation Image"
              />
            )}
          </div>
        </div>
      )}

      {/* Next/Finish Button */}
      <button
        className="mt-auto p-4 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition-colors w-3/4"
        onClick={isLastQuestion ? onFinish : onNext}
      >
        {isLastQuestion ? "Finish" : "Next"}
      </button>
    </div>
  );
};

export default Feedback;
