import { loginStart, loginSuccess, loginFailure, logout } from '../../../stores/authSlice';
import axios from './axiosConfig';
import api from './axiosConfig'; // Your Axios instance

// Standard email/password login
export const login = (email: string, password: string) => async (dispatch: any) => {
  try {
    dispatch(loginStart());

    // Call the login API (cookies will be handled automatically because of withCredentials)
    const response = await api.post('/auth/login', { email, password });

    // Extract user info from the response
    const { user } = response.data;

    // Dispatch login success with user info
    dispatch(loginSuccess({ user }));

    // Optionally, log the user info to verify that the response is correct
    console.log('User info after login:', user);

  } catch (error: any) {
    // Handle login error
    const errorMessage = error.response?.data?.message || 'Login failed';
    console.error('Login error:', errorMessage);
    dispatch(loginFailure(errorMessage));
  }
};
// Standard email/password registration
export const register = (email: string, password: string, fullName: string, school: string) => async (dispatch: any) => {
  try {
    dispatch(loginStart());

    // Call the register API (the cookie will automatically be handled by the backend)
    const response = await api.post('/auth/register', {
      email,
      password,
      fullName,
      school,
    });

    // After successful registration, call verify-token to ensure the JWT is valid
    await dispatch(verifyAuth());

    // After verifying token, you can navigate to the next page or log success
    dispatch(loginSuccess({ user: response.data.user }));

    return response; // Return the response so you can use it in your component
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Registration failed';
    dispatch(loginFailure(errorMessage));
    throw error;
  }
};

// Google login initiation
export const googleLogin = (): any => (dispatch: any) => {
  // Redirect the user to Google OAuth
  window.location.href = `${process.env.REACT_APP_SERVER_URL}/auth/google`;
};

// Google OAuth callback handling
export const handleGoogleCallback = (navigate: any): any => async (dispatch: any) => {
  try {
    dispatch(loginStart());

    // The backend handles the cookie, so we just need to check user info
    const response = await api.get('/auth/google/callback');

    // Extract user info from the response
    const { user } = response.data;

    // Dispatch login success with user info
    dispatch(loginSuccess({ user }));

    // Redirect the user after login
    navigate('/quiz');
  } catch (error: any) {
    // Handle Google login error
    const errorMessage = error.response?.data?.message || 'Google login failed';
    dispatch(loginFailure(errorMessage));
  }
};

// Complete Profile action
export const completeProfile = (fullName: string, school: string): any => async (dispatch: any) => {
  try {
    dispatch(loginStart());

    // The cookie-based token will be automatically sent with the request
    const response = await api.post('/auth/complete-profile', { fullName, school });

    // Extract updated user info
    const { user } = response.data;

    // Dispatch success with updated user info
    dispatch(loginSuccess({ user }));

    // Return the full response in case it's needed in the component
    return response;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Profile completion failed';
    dispatch(loginFailure(errorMessage));
    throw error; // Make sure to throw the error so the component can catch it
  }
};

// Logout function
export const logoutUser = () => async (dispatch: any) => {
  // Call the logout API to clear the cookie
  await api.post('/auth/logout');

  // Dispatch the logout action to clear user info from Redux
  dispatch(logout());
};
// authActions.ts

// Verify if the user is authenticated by checking JWT in the cookie
export const verifyAuth = (): any => async (dispatch: any) => {
  try {
    dispatch(loginStart());

    // Call the backend to verify the JWT in the cookie
    const response = await axios.get('/auth/verify-token', {
      withCredentials: true, // Ensures the cookies are sent
    });
    const { user } = response.data;

    // If successful, dispatch loginSuccess with user info
    dispatch(loginSuccess({ user }));
  } catch (error) {
    // If verification fails, dispatch loginFailure
    dispatch(loginFailure("Authentication failed"));
  }
};
