import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FormData {
  question_text: string;
  options: string[];
  correct_answers: string[];
  subject_id: string;
  level_id: string;
  explanation_text: string;
  topic: string[]; // Updated to an array of strings
  school: string;
  year: string;
  question_num: string;
}

// These are the keys that will use the setFormDataString action
type FormDataStringKeys =
  | "question_text"
  | "subject_id"
  | "level_id"
  | "explanation_text"
  | "school"
  | "year"
  | "question_num";

type FormDataArrayKeys = "options" | "correct_answers" | "topic"; // Added "topic" to the array keys

export type { FormDataStringKeys };

interface UploadState {
  formData: FormData;
  selectedFile: File | null;
  explanationFile: File | null;
  questionId: string | null;
  isDragging: boolean;
}

const initialState: UploadState = {
  formData: {
    question_text: '',
    options: ['A', 'B', 'C', 'D'],
    correct_answers: [''],
    subject_id: '',
    level_id: '',
    explanation_text: '',
    topic: [], // Initialized as an empty array
    school: '',
    year: '',
    question_num: '',
  },
  selectedFile: null,
  explanationFile: null,
  questionId: null,
  isDragging: false,
};

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setFormDataString(
      state,
      action: PayloadAction<{ name: FormDataStringKeys; value: string }>
    ) {
      state.formData[action.payload.name] = action.payload.value;
    },
    setFormDataArray(
      state,
      action: PayloadAction<{ name: FormDataArrayKeys; value: string[] }>
    ) {
      state.formData[action.payload.name] = action.payload.value;
    },
    setFile(state, action: PayloadAction<File | null>) {
      state.selectedFile = action.payload;
    },
    setExplanationFile(state, action: PayloadAction<File | null>) {
      state.explanationFile = action.payload;
    },
    setQuestionId(state, action: PayloadAction<string | null>) {
      state.questionId = action.payload;
    },
    setIsDragging(state, action: PayloadAction<boolean>) {
      state.isDragging = action.payload;
    },
    resetForm(state) {
      state.formData = initialState.formData;
      state.selectedFile = null;
      state.explanationFile = null; // Reset explanation file as well
      state.questionId = null;
      state.isDragging = false;
    },
  },
});

export const {
  setFormDataString,
  setFormDataArray,
  setFile,
  setExplanationFile,
  setQuestionId,
  setIsDragging,
  resetForm,
} = uploadSlice.actions;

export default uploadSlice.reducer;
