import React from 'react';

const Confetti: React.FC = () => {
  const confettiColors = ['red', 'blue', 'green', 'yellow', 'purple', 'orange'];
  const confettiPieces = Array.from({ length: 20 });

  return (
    <div className="fixed top-0 left-0 w-full h-full pointer-events-none z-50">
      {confettiPieces.map((_, index) => (
        <div
          key={index}
          className={`absolute top-0 left-1/2 w-2 h-4 bg-${confettiColors[index % confettiColors.length]}-500 opacity-0 animate-confetti`}
          style={{
            animationDelay: `${Math.random() * 2}s`,
            transform: `rotate(${Math.random() * 360}deg)`,
            left: `${Math.random() * 100}vw`,
          }}
        ></div>
      ))}
    </div>
  );
};

export default Confetti;
