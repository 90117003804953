import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetQuiz } from "../../../stores/quizSlice";
import Confetti from "./Confetti";

interface QuizCompletedProps {
  score: number;
  longestStreak: number;
  onRestart: () => void;
  onBackToMenu: () => void;
}

const QuizCompleted: React.FC<QuizCompletedProps> = ({
  score,
  longestStreak,
  onRestart,
  onBackToMenu,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // You can play a sound effect here if desired
    // Example: new Audio('/path/to/celebration-sound.mp3').play();
  }, []);

  const handleRestart = () => {
    dispatch(resetQuiz());
    onRestart(); // Call the passed-in onRestart function
  };

  const handleBackToMenuClick = () => {
    dispatch(resetQuiz()); // Reset the quiz before going back to the main menu
    onBackToMenu(); // Call the passed-in onBackToMenu function
  };

  return (
    <div className="relative flex flex-col items-center justify-center p-8 bg-gray-100 rounded-lg shadow-lg w-full max-w-lg min-h-[350px] mx-auto text-center">
      {/* Confetti celebration */}
      <Confetti />

      <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-6">
        🎉 Quiz Completed!
      </h2>
      <p className="text-lg text-gray-700 mb-4">
        Your Final Score: <strong>{score}</strong>
      </p>
      <p className="text-lg text-gray-700 mb-8">
        Your Longest Streak: <strong>{longestStreak}</strong>
      </p>
      <div className="flex space-x-4">
        <button
          className="p-4 bg-green-600 text-white rounded-full shadow-lg hover:bg-green-700 transition-colors w-1/2"
          onClick={handleRestart}
        >
          Play Again
        </button>
        <button
          className="p-4 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition-colors w-1/2"
          onClick={handleBackToMenuClick}
        >
          Main Menu
        </button>
      </div>
    </div>
  );
};

export default QuizCompleted;
