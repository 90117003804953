import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { FaFire, FaTrophy } from "react-icons/fa";

interface StreakDisplayProps {
  streak: number;
  score: number;
}

const StreakDisplay: React.FC<StreakDisplayProps> = ({ streak, score }) => {
  const controls = useAnimation();

  useEffect(() => {
    if (streak > 0) {
      controls.start({
        scale: [1, 1.05, 1], // Reduced scale factor for a more subtle effect
        transition: { duration: 0.25, ease: "easeInOut" }, // Slightly longer duration for smoother animation
      });
    }
  }, [streak, controls]);

  const intensityLevels = {
    initial: { color: "#4E4E4E" }, // dark gray, unlit fire
    low: { color: "#FF8A65" }, // soft orange-red
    medium: { color: "#FF7043" }, // brighter orange-red
    high: { color: "#F4511E" }, // deep orange
    extreme: { color: "#BF360C" }, // dark reddish-orange
  };

  const getIntensityLevel = (streak: number) => {
    if (streak > 3) return "extreme";
    if (streak > 2) return "high";
    if (streak > 1) return "medium";
    if (streak > 0) return "low";
    return "initial";
  };

  const intensity = getIntensityLevel(streak);
  const currentIntensity = intensityLevels[intensity];

  return (
    <div className="flex items-center justify-center mt-4 w-full">
      {/* Score Display */}
      <div className="flex items-center justify-end text-base font-bold text-gray-800 md:text-lg lg:text-xl w-1/2 pr-4">
        <FaTrophy className="text-base md:text-lg lg:text-xl mr-2" />
        <span className="flex items-center">
          Score:
          <span className="ml-2 text-base md:text-lg lg:text-xl">{score}</span>
        </span>
      </div>

      {/* Vertical Divider */}
      <div className="h-8 w-px bg-gray-300 mx-4"></div>

      {/* Streak Display */}
      <motion.div
        className="flex items-center space-x-1 w-1/2 pl-4"
        initial="initial"
        animate={controls}
        style={{
          color: currentIntensity.color,
          whiteSpace: "nowrap",
        }}
      >
        <FaFire className="text-base md:text-lg lg:text-xl" />
        <motion.p
          className="text-base md:text-lg lg:text-xl font-bold"
          style={{ scale: 1 }}
        >
          Streak: <strong>{streak}</strong>
        </motion.p>
      </motion.div>
    </div>
  );
};

export default StreakDisplay;
