import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../stores/store";
import { register } from "../services/authActions";
import { fetchSchools, School } from "../../../services/schoolService";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import InputField from "../components/InputField";
import ErrorMessage from "../components/ErrorMessage";

const Register: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [school, setSchool] = useState("");
  const [schools, setSchools] = useState<School[]>([]);
  const [loading, setLoading] = useState(true);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Fetch schools when the component mounts
  useEffect(() => {
    const loadSchools = async () => {
      try {
        const fetchedSchools = await fetchSchools();
        setSchools(fetchedSchools);
      } catch (error) {
        console.error("Failed to fetch schools", error);
      } finally {
        setLoading(false);
      }
    };
    loadSchools();
  }, []);

  const validatePassword = (password: string) => {
    const minLength = /.{8,}/;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;
    const hasNumber = /[0-9]/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    if (!minLength.test(password)) {
      return "Password must be at least 8 characters long.";
    }
    if (!hasUpperCase.test(password)) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!hasLowerCase.test(password)) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!hasNumber.test(password)) {
      return "Password must contain at least one number.";
    }
    if (!hasSpecialChar.test(password)) {
      return "Password must contain at least one special character.";
    }
    return null;
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const validationError = validatePassword(newPassword);
    setPasswordError(validationError);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    if (newConfirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError(null);
    }
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationError = validatePassword(password);
    if (validationError) {
      setPasswordError(validationError);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return;
    }

    setRegisterLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await dispatch(
        register(email, password, fullName, school)
      );
      if (response.status === 201) {
        setSuccess(true);
        setTimeout(() => {
          navigate("/quiz"); // Redirect after successful registration
        }, 1000);
      } else {
        setError("Something went wrong.");
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        setError(error.response.data.message || "Email is already registered.");
      } else {
        setError("An unknown error occurred.");
      }
    } finally {
      setRegisterLoading(false);
    }
  };

  if (loading) {
    return (
      <Loader
        loadingTexts={["Looking for the register form...", "Almost there..."]}
      />
    );
  }

  return (
    <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
      <button
        onClick={() => navigate(-1)}
        className="text-blue-600 hover:text-blue-800 mb-4"
      >
        &larr; Back
      </button>

      <h2 className="text-2xl font-bold text-center mb-6">Register</h2>

      {error && <ErrorMessage message={error} />}
      {success && (
        <p className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          Registration successful! Redirecting...
        </p>
      )}

      <form onSubmit={handleRegister}>
        <InputField
          id="fullName"
          type="text"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          placeholder="Full Name"
          disabled={registerLoading}
        />
        <InputField
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          disabled={registerLoading}
        />
        <InputField
          id="password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Password"
          disabled={registerLoading}
          errorMessage={passwordError || undefined}
        />
        <InputField
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          placeholder="Confirm Password"
          disabled={registerLoading}
          errorMessage={confirmPasswordError || undefined}
        />

        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">
            Select School:
          </label>
          <select
            value={school}
            onChange={(e) => setSchool(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded"
            disabled={registerLoading}
            required
          >
            <option value="">Select a school</option>
            {schools.map((school) => (
              <option key={school._id} value={school._id}>
                {school.name}
              </option>
            ))}
          </select>
        </div>

        <button
          type="submit"
          className={`w-full p-3 text-white rounded ${
            registerLoading ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"
          }`}
          disabled={registerLoading}
        >
          {registerLoading ? "Registering..." : "Register"}
        </button>
      </form>
    </div>
  );
};

export default Register;
