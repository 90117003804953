import axios from 'axios';

// Setup the base URL for your API
const API_URL = process.env.REACT_APP_SERVER_URL;
axios.defaults.baseURL = API_URL;

// Enable sending cookies with every request
axios.defaults.withCredentials = true;

export default axios;
