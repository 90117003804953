import React from "react";
import Loader from "./Loader"; // Import your existing Loader component

interface FullScreenLoaderProps {
  loadingTexts: string[]; // Accept an array of texts
  interval?: number; // Optional interval for text change, default to 2.5 seconds
}

const FullScreenLoader: React.FC<FullScreenLoaderProps> = ({
  loadingTexts,
  interval = 2500,
}) => {
  return (
    <div className="fixed inset-0 bg-gray-100 bg-opacity-70 z-50 flex justify-center items-center">
      <Loader loadingTexts={loadingTexts} interval={interval} />
    </div>
  );
};

export default FullScreenLoader;
