import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StreakDisplay from "../components/StreakDisplay";

interface QuizHeaderProps {
  currentQuestionIndex: number;
  totalQuestions: number;
  streak: number;
  score: number;
  onBackToMenu: () => void;
  showFeedback: boolean;
}

const QuizHeader: React.FC<QuizHeaderProps> = ({
  currentQuestionIndex,
  totalQuestions,
  streak,
  score,
  onBackToMenu,
  showFeedback,
}) => {
  // Handle edge cases for invalid totalQuestions
  if (totalQuestions <= 0) {
    return (
      <div className="w-full bg-white p-4 shadow z-10 flex items-center justify-between">
        <button
          onClick={onBackToMenu}
          className="text-black hover:text-gray-800 transition-colors"
        >
          <ArrowBackIcon style={{ fontSize: "2rem" }} />
        </button>
        <div className="text-center w-full">
          <p>No questions available.</p>
        </div>
      </div>
    );
  }

  const validQuestionIndex = Math.max(0, currentQuestionIndex);

  // Determine the progress bar width
  let progressBarWidth =
    totalQuestions === 1
      ? showFeedback
        ? 100
        : 0
      : validQuestionIndex === totalQuestions - 1 && showFeedback
      ? 100
      : (validQuestionIndex / (totalQuestions - 1)) * 100;

  return (
    <div className="w-full bg-white p-4 shadow z-10 flex items-center justify-between">
      <button
        onClick={onBackToMenu}
        className="text-black hover:text-gray-800 transition-colors"
      >
        <ArrowBackIcon style={{ fontSize: "2rem" }} />
      </button>

      <div className="w-full max-w-2xl mx-auto flex flex-col items-center">
        <div className="text-gray-600 text-center font-medium text-sm md:text-base lg:text-lg mb-2">
          {`Question ${validQuestionIndex + 1} of ${totalQuestions}`}
        </div>
        <div className="h-2 bg-gray-200 rounded-full w-full">
          <div
            className="h-full bg-gradient-to-r from-blue-400 to-green-400 rounded-full transition-width duration-500 ease-in-out"
            style={{ width: `${progressBarWidth}%` }}
          ></div>
        </div>

        {/* Streak and Score Display */}
        <StreakDisplay streak={streak} score={score} />
      </div>
    </div>
  );
};

export default QuizHeader;
