import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Question {
  _id: string;  // MongoDB ObjectId as a string
  question_text: string;
  question_image_url?: string;
  options: string[];
  correct_answers: string[];
  subject_id: string;  // MongoDB ObjectId as a string
  level_id: string;  // MongoDB ObjectId as a string
  explanation_text?: string;
  explanation_image_url?: string;
  topic: string[];
}

interface QuizState {
  availableQuizzes: string[];
  selectedQuiz: string | null;
  questions: Question[];
  currentQuestionIndex: number;
  selectedOptions: string[];
  score: number;
  streak: number;
  longestStreak: number;
  showFeedback: boolean;
  isFeedbackCorrect: boolean;
  feedbackMessage: string;
  showResult: boolean;
  showConfetti: boolean;
}

const initialState: QuizState = {
  availableQuizzes: ['Quiz 1', 'Quiz 2', 'Quiz 3'], // Example quiz names
  selectedQuiz: null,
  questions: [],
  currentQuestionIndex: 0,
  selectedOptions: [],
  score: 0,
  streak: 0,
  longestStreak: 0,
  showFeedback: false,
  isFeedbackCorrect: false,
  feedbackMessage: '',
  showResult: false,
  showConfetti: false,
};

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setAvailableQuizzes(state, action: PayloadAction<string[]>) {
      state.availableQuizzes = action.payload;
    },
    setSelectedQuiz(state, action: PayloadAction<string>) {
      state.selectedQuiz = action.payload;
      // Reset quiz state when a new quiz is selected
      state.questions = [];
      state.currentQuestionIndex = 0;
      state.selectedOptions = [];
      state.score = 0;
      state.streak = 0;
      state.longestStreak = 0;
      state.showFeedback = false;
      state.isFeedbackCorrect = false;
      state.feedbackMessage = '';
      state.showResult = false;
      state.showConfetti = false;
    },
    setQuestions(state, action: PayloadAction<Question[]>) {
      state.questions = action.payload;
    },
    setCurrentQuestionIndex(state, action: PayloadAction<number>) {
      state.currentQuestionIndex = action.payload;
    },
    setSelectedOptions(state, action: PayloadAction<string[]>) {
      state.selectedOptions = action.payload;
    },
    setScore(state, action: PayloadAction<number>) {
      state.score = action.payload;
    },
    setStreak(state, action: PayloadAction<number>) {
      state.streak = action.payload;
    },
    setLongestStreak(state, action: PayloadAction<number>) {
      state.longestStreak = action.payload;
    },
    setShowFeedback(state, action: PayloadAction<boolean>) {
      state.showFeedback = action.payload;
    },
    setIsFeedbackCorrect(state, action: PayloadAction<boolean>) {
      state.isFeedbackCorrect = action.payload;
    },
    setFeedbackMessage(state, action: PayloadAction<string>) {
      state.feedbackMessage = action.payload;
    },
    setShowResult(state, action: PayloadAction<boolean>) {
      state.showResult = action.payload;
    },
    setShowConfetti(state, action: PayloadAction<boolean>) {
      state.showConfetti = action.payload;
    },
    resetQuiz(state) {
      state.currentQuestionIndex = 0;
      state.selectedOptions = [];
      state.score = 0;
      state.streak = 0;
      state.showFeedback = false;
      state.showResult = false;
      state.showConfetti = false;
    },
  },
});

export const {
  setAvailableQuizzes,
  setSelectedQuiz,
  setQuestions,
  setCurrentQuestionIndex,
  setSelectedOptions,
  setScore,
  setStreak,
  setLongestStreak,
  setShowFeedback,
  setIsFeedbackCorrect,
  setFeedbackMessage,
  setShowResult,
  setShowConfetti,
  resetQuiz,
} = quizSlice.actions;

export default quizSlice.reducer;
