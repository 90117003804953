import axios from 'axios';

// Define the Question interface
export interface Question {
  _id: string;
  question_text: string;
  options: string[];
  correct_answers: string[];
  subject_id: string;
  level_id: string;
  explanation_text?: string;
  explanation_image_url?: string;
  question_image_url?: string;
  topic: string[];
  school: string;
  year: string;
  question_num: string;
}


// Define the Level interface
export interface Level {
  _id: string;
  name: string;
}

// Access the environment variables
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// Function to submit a new question
export const submitQuestion = async (formData: Omit<Question, '_id'>): Promise<Question> => {
  const response = await axios.post<Question>(`${SERVER_URL}/questions`, formData);
  return response.data;
};

// Function to upload a question image
export const uploadQuestion = async (questionId: string, file: File) => {
  const formData = new FormData();
  formData.append('image', file);

  try {
    const response = await axios.post(
      `${SERVER_URL}/questions/upload-question/${questionId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error uploading image:', error.message);
    } else {
      console.error('Unexpected error:', error);
    }
    throw error;
  }
};

// Function to upload an explanation image
export const uploadExplanation = async (questionId: string, file: File) => {
  const formData = new FormData();
  formData.append('image', file);

  try {
    const response = await axios.post(
      `${SERVER_URL}/questions/upload-explanation/${questionId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error uploading image:', error.message);
    } else {
      console.error('Unexpected error:', error);
    }
    throw error;
  }
};



// Function to fetch all levels
export const fetchLevels = async (): Promise<Level[]> => {
  try {
    const response = await axios.get<Level[]>(`${SERVER_URL}/levels`);
    return response.data;
  } catch (error) {
    console.error('Error fetching levels:', error);
    throw error;
  }
};

export interface Subject {
  _id: string;
  name: string;
}


// Function to fetch all subjects
export const fetchSubjects = async (): Promise<Subject[]> => {
  try {
    const response = await axios.get<Subject[]>(`${SERVER_URL}/subjects`);
    return response.data;
  } catch (error) {
    console.error('Error fetching subjects:', error);
    throw error;
  }
};