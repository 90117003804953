import axios from 'axios';

// Define the interface for a School
export interface School {
  _id: string;
  name: string;
  type: string;
  location: string;
}

// Access the environment variables
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// Function to fetch all schools
export const fetchSchools = async (): Promise<School[]> => {
  try {
    const response = await axios.get<School[]>(`${SERVER_URL}/schools`);
    return response.data;
  } catch (error) {
    console.error('Error fetching schools:', error);
    throw error;
  }
};

// Function to fetch a school by ID
export const fetchSchoolById = async (id: string): Promise<School> => {
  try {
    const response = await axios.get<School>(`${SERVER_URL}/schools/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching school with ID ${id}:`, error);
    throw error;
  }
};
