import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Quiz from "./features/quiz/components/Quiz";
import QuizSelector from "./features/quiz/components/QuizSelector";
import Login from "./features/auth/components/Login";
import Register from "./features/auth/components/Register";
import GoogleCallback from "./features/auth/components/GoogleCallback";
import NavBar from "./components/NavBar";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./stores/store";
import "./features/auth/services/axiosConfig";
import CompleteProfile from "./features/auth/components/CompleteProfile";
import FullScreenLoader from "./components/FullScreenLoader"; // Import the loader
import { verifyAuth } from "./features/auth/services/authActions"; // Import the verifyAuth action

const App: React.FC = () => {
  const [selectedQuiz, setSelectedQuiz] = useState<string | null>(null);
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.user !== null // Check if user exists
  );
  const [loadingAuth, setLoadingAuth] = useState(true); // Add a loading state
  const dispatch = useDispatch(); // Initialize dispatch

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await dispatch(verifyAuth()); // Verify authentication status using cookies
      } catch (error) {
        console.error("Authentication verification failed:", error);
      } finally {
        setLoadingAuth(false); // Mark auth check as complete
      }
    };

    checkAuth(); // Call the auth verification function on load
  }, [dispatch]);

  const handleStartQuiz = (quizName: string) => {
    setSelectedQuiz(quizName);
  };

  const handleBackToMenu = () => {
    setSelectedQuiz(null);
  };

  if (loadingAuth) {
    return (
      <FullScreenLoader
        loadingTexts={["Authenticating...", "Please wait..."]}
      />
    ); // Show full-screen loader until authentication is checked
  }

  return (
    <Router>
      <NavBar />
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
        <Routes>
          {/* Public Routes */}
          <Route
            path="/login"
            element={
              isAuthenticated ? <Navigate to="/quiz" replace /> : <Login />
            }
          />
          <Route
            path="/register"
            element={
              isAuthenticated ? <Navigate to="/quiz" replace /> : <Register />
            }
          />

          {/* Ensure GoogleCallback is always accessible */}
          <Route path="/google/callback" element={<GoogleCallback />} />

          {/* Profile completion route */}
          <Route path="/complete-profile" element={<CompleteProfile />} />

          {/* Redirect from root */}
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/quiz" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          {/* Protected Quiz Routes */}
          <Route
            path="/quiz"
            element={
              isAuthenticated ? (
                selectedQuiz ? (
                  <Quiz
                    onBackToMenu={handleBackToMenu}
                    selectedTopic={selectedQuiz}
                  />
                ) : (
                  <QuizSelector
                    onStartQuiz={handleStartQuiz}
                    onBackToMenu={handleBackToMenu}
                  />
                )
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
