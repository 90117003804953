import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import quizReducer from './quizSlice';
import uploadReducer from './uploadSlice';
import preloadReducer from './preloadSlice';
import authReducer from './authSlice';  // Import the auth slice
import { useDispatch } from 'react-redux';

export const store = configureStore({
  reducer: {
    quiz: quizReducer,
    upload: uploadReducer,
    preload: preloadReducer,
    auth: authReducer,  // Add the auth reducer here
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Custom hook to use dispatch with correct typing
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
