import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../stores/store";
import { useNavigate } from "react-router-dom";
import { verifyAuth } from "../services/authActions"; // Call to verify if user is logged in

const GoogleCallback: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // Loading state

  useEffect(() => {
    const verifyUserAuth = async () => {
      try {
        // Call to verify if the user is authenticated via the cookie
        await dispatch(verifyAuth());

        setLoading(false); // Set loading to false once verification is done
        navigate("/quiz"); // Redirect to quiz page or dashboard
      } catch (err) {
        console.error("Authentication failed:", err);
        setError("Authentication failed.");
        setLoading(false);
      }
    };

    verifyUserAuth();
  }, [dispatch, navigate]);

  if (loading) {
    return <div>Loading...</div>; // Prevent redirection until token is processed
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div>Logging in with Google...</div>;
};

export default GoogleCallback;
